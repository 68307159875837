<template>
  <div class="base-light-container">
    <div class="light-data-query">
      <div :class="['light-data-tab', curTab == 0 ? 'tab-active' : '']" @click="switchTab(0)">{{showLang('com.state.all.types')}}</div>
      <template v-for="(item) in selectedTypes">
        <div v-if="selectedTypes.length > 1" :key="item" :class="['light-data-tab', curTab == item ? 'tab-active' : '']" @click="switchTab(item)">
          {{types[item]}}</div>
      </template>
      <div :class="['light-data-tab', curTab == -1 ? 'tab-active' : '']" @click="switchTab(-1)">{{showLang('com.gis.location')}}</div>
    </div>
    <div v-if="curTab >= 0" class="light-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')"  border resizable show-overflow show-header-overflow keep-source ref="table" align="center" :key="dataRefresh"
        @checkbox-change="selectChangeEvent" :data="list" height="500" :scroll-y="{enabled: true, gt: 0}" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}"
        :merge-cells="mergeData">
        <vxe-column width="45" type="checkbox" field="checkbox"></vxe-column>
         <vxe-column type="seq" field="seq" width="60"></vxe-column>
        <vxe-column v-if="colCanShow('nm')" width="250" field="name" align="left" :title="showLang('com.tab.title')">
          <template #default="{ row }">
            <a href="javascript:void(0);" @click="lampDetails(row)">{{row.name}}</a>
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('id')" field="code" align="left" :title="showLang('com.ins.last.id')" minWidth="150">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('ch')" field="ch" align="left" :title="showLang('com.ins.ch')" width="100">
          <template #default="{ row }">
            K{{row.ch}}:{{row.n}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('ol')" field="online" :title="showLang('com.state')" width="50">
          <template #default="params">
            <img :src="getStateImage(params)" />
          </template>
        </vxe-column>

        <vxe-column v-if="colCanShow('u')" field="u" :title="`${showLang('com.lighting.V')}(V)`" width="90">
          <template #default="{ row }">
            {{img.val(row.u, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('c')" field="c" :title="`${showLang('com.lighting.I')}(A)`" width="90">
          <template #default="{ row }">
            {{img.val(row.c, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('p')" field="p" :title="`${showLang('com.lighting.P')}(W)`" width="90">
          <template #default="{ row }">
            {{img.val(row.p, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('op')" field="op" :title="`${showLang('com.lighting.op')}(%)`" width="90">
          <template #default="{ row }">
            {{img.val(row.op, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('q')" field="q" :title="`${showLang('com.lighting.q')}(KWh)`" width="100">
          <template #default="{ row }">
            {{img.val(row.q, 3)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('lo')" field="lo" :title="showLang('com.lighting.lo')" width="120">
          <template #default="{ row }">
            {{img.val(row.lo, 0)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('rssi')" field="rssi" :title="showLang('com.lighting.rssi')" width="100">
          <template #default="{ row }">
            {{img.val(row.rssi, 0)}}
          </template>
        </vxe-column>
         <vxe-column v-if="colCanShow('sun')" field="sun" :title="showLang('com.lighting.sun')" width="100">
          <template #default="{ row }">
            {{img.val(row.sun, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('oc')" field="oc" :title="showLang('com.lighting.oc')" width="90">
          <template #default="{ row }">
            {{img.val(row.oc, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('lu')" field="lu" :title="showLang('com.lighting.lu')+'/V'" width="120">
          <template #default="{ row }">
            {{img.val(row.lu, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('cl')" field="cl" :title="`${showLang('com.lighting.cl')}(mA)`" width="120">
          <template #default="{ row }">
            {{img.val(row.cl, 2)}} 
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('tc')" field="tc" :title="`${showLang('com.lighting.T')}(℃)`" width="80">
          <template #default="{ row }">
            {{img.val(row.tc, 2)}}
          </template>
        </vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.ins.time.last')" sortable width="160">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <!-- <vxe-column field="result" :title="showLang('com.ins.result')" sortable fixed="right">
          <template #default="{ row }">
            <a  href="javascript:void(0);" >{{row.result}}</a>
          </template>
        </vxe-column> -->
        <vxe-column field="result" align="left" :title="showLang('com.ins.result')" minWidth="300" sortable :show-overflow-tooltip="true" fixed="right">
          <template #default="{ row }">
            {{row._message}}
          </template>
        </vxe-column>
        <vxe-column width="80" fixed="right" field="lampDetails">
          <template #default="{ row }">
            <a href="javascript:void(0);" @click="lampDetails(row)">{{showLang('com.data.detail')}}</a>
            <!-- <Button size="small" type="success" @click="lampDetails(params.row)">查看详情</Button> -->
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="light-data-area" v-else style="height:800px">
      <lampMap :gainDeviceType="deviceArray" />
    </div>
    <template>
      <Modal v-model="showLamp" :title="`${showLang('com.site')}:${siteName}----${showLang('com.device.pole')}:${showLampData.name}`" width="1520"
        :styles="{top: '20px'}">
        <detailsLamp :option="showLampData" :lampList="showLampList" />
        <div slot="footer">
          <Button type="default" @click="showLamp = false">{{showLang('com.op.close')}}</Button>
        </div>
      </Modal>
    </template>
    <template v-if="cmdContent.code">
      <Modal v-model="showCmdContent" :title="showLang('com.ins.result')" width="800" :styles="{top: '120px'}">
        <div v-if="cmdContent.code == 'getInfo'">
          <p>{{showLang('com.site.hw')}}：{{cmdContent.content.hardware}}；</p>
          <p>{{showLang('com.site.sw')}}：{{cmdContent.content.software}}；</p>
          <p>{{showLang('com.date.factory')}}：{{cmdContent.content.manufacture}}；</p>
        </div>
        <div v-else-if="cmdContent.code == 'getclock'">
          <p>{{showLang('com.date.current')}}：{{cmdContent.content.nowTime}}； {{weeks[cmdContent.content.nowWeek]}}；</p>
        </div>
        <div v-else-if="cmdContent.code == 'getNetwork'">
          <p>{{showLang('com.ins.server.IP')}}：{{cmdContent.content.host}}； </p>
          <p>{{showLang('com.ins.server')}}：{{cmdContent.content.port}}；</p>
          <p>{{showLang('com.ins.heartbeat.time')}}：{{cmdContent.content.heart}}s；</p>
        </div>
        <div v-else-if="cmdContent.code == 'getSimInfo'">
          <p>IMEI：{{cmdContent.content.imei}}； </p>
          <p>ICCID：{{cmdContent.content.iccid}}；</p>
          <p>RSSI：{{cmdContent.content.rssi}}；</p>
        </div>
        <div v-else-if="cmdContent.code == 'getUnionBright'" style="display: flex; flex-direction: column;">
          <template v-for="c in 2">
            <div v-for="n in 6" :key="`ch${c}${n}`">
              {{showLang('com.ins.ch')}}{{c}}，{{showLang('com.date.period')}}{{n}}：{{showLang('com.lighting.op')}}：{{cmdContent.content[`bright${c}${n}`]}}，{{showLang('com.lighting.oc')}}：{{cmdContent.content[`color${c}${n}`]}}；
            </div>
          </template>
        </div>
        <div v-else-if="cmdContent.code == 'getUnionParam'">
          <p>{{showLang('com.lighting.delay.lamp1')}}：{{cmdContent.content.keep1}}s</p>
          <p>{{showLang('com.lighting.delay.lamp2')}}：{{cmdContent.content.keep2}}s</p>
          <p>
            {{showLang('com.lighting.is.sensing')}}：{{cmdContent.content.sensor == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')}}；
          </p>
          <p>
            {{showLang('com.lighting.is.signal')}}：{{cmdContent.content.txen > 10 ? this.showLang('com.state.effective'):this.showLang('com.state.invalid')}}；
          </p>
          <p>{{showLang('com.lighting.send.cycle')}}：{{cmdContent.content.period}}s</p>
          <p>{{showLang('com.lighting.linkage.number')}}：{{cmdContent.content.rfsn}}；</p>
          <p>433{{showLang('com.lighting.send.power')}}：{{cmdContent.content.txpower}}；</p>
          <p>433{{showLang('com.lighting.channel')}}：{{cmdContent.content.channel}}；</p>
          <p>{{showLang('com.lighting.response.range')}}：{{cmdContent.content.range}}；</p>
          <p>
            {{showLang('com.lighting.is.put.signal')}}：{{cmdContent.content.rxen == 1 ? this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}}；
          </p>
          <p>
            {{showLang('com.lighting.linkage.mode')}}：{{cmdContent.content.mode == 1 ?this.showLang('com.lighting.Linkage.control') : this.showLang('com.lighting.lamp.contact')}}；
          </p>
          <p>{{showLang('com.lighting.repeat.num')}}：{{cmdContent.content.repeat}}；</p>
        </div>
        <div v-else-if="cmdContent.code == 'getDayPlan1'" style="display: flex; flex-direction: column;">
          <div v-for="n in 6" :key="n">
            <p>{{showLang('com.date.period')}}：{{n}}</p>
            <p>{{showLang('com.date.time')}}：{{(cmdContent.content[`${n-1}`]||{})[`min`]}}min,</p>
            <p>1{{showLang('com.ins.ch.light')}}：{{(cmdContent.content[`${n-1}`]||{})[`ch1`]}}，</p>
            <p>1{{showLang('com.ins.ch.color')}}：{{(cmdContent.content[`${n-1}`]||{})[`c1`]}},</p>
            <p>2{{showLang('com.ins.ch.light')}}：{{(cmdContent.content[`${n-1}`]||{})[`ch2`]}}，</p>
            <p>2{{showLang('com.ins.ch.color')}}：{{(cmdContent.content[`${n-1}`]||{})[`c2`]}};</p>
          </div>
        </div>
        <div v-else-if="cmdContent.code == 'getDayPlan2'" style="display: flex; flex-direction: column;">
          <div v-for="n in 6" :key="n">
            <p>{{showLang('com.date.period')}}：{{n}}</p>
            <p>{{showLang('com.date.time')}}：{{(cmdContent.content[`${n-1}`]||{})[`min`]}}min,</p>
            <p>1{{showLang('com.ins.ch.light')}}：{{(cmdContent.content[`${n-1}`]||{})[`ch1`]}}，</p>
            <p>1{{showLang('com.ins.ch.color')}}：{{(cmdContent.content[`${n-1}`]||{})[`c1`]}},</p>
            <p>2{{showLang('com.ins.ch.light')}}：{{(cmdContent.content[`${n-1}`]||{})[`ch2`]}}，</p>
            <p>2{{showLang('com.ins.ch.color')}}：{{(cmdContent.content[`${n-1}`]||{})[`c2`]}};</p>
          </div>
        </div>
        <div v-else-if="cmdContent.code == 'getPlanType'">
          {{showLang('com.but.tableType')}}：{{cmd.content.type == 0 ? showLang('com.but.timing'):showLang('com.but.ontime')}}；</div>
        <div v-else-if="cmdContent.code == 'getAlarmStatus'">
          <p>{{showLang('com.lighting.V.alarm')}}：{{cmdContent.content.voltage?showLang('com.state.enable'):showLang('com.state.disable')}}；</p>
          <p>{{showLang('com.lighting.I.alarm')}}：{{cmdContent.content.current?showLang('com.state.enable'):showLang('com.state.disable')}}；</p>
          <p>{{showLang('com.lighting.T.alarm')}}：{{cmdContent.content.temp?showLang('com.state.enable'):showLang('com.state.disable')}}；</p>
        </div>
        <div v-else-if="cmdContent.code == 'getAlarmRange'">
          <p>{{showLang('com.lighting.V.min')}}：{{cmdContent.content.vLow}}；</p>
          <p>{{showLang('com.lighting.V.max')}}：{{cmdContent.content.vHigh}}；</p>
          <p>{{showLang('com.lighting.I.min')}}：{{cmdContent.content.cLow}}；</p>
          <p>{{showLang('com.lighting.I.max')}}：{{cmdContent.content.cHigh}}；</p>
          <p>{{showLang('com.lighting.T.min')}}：{{cmdContent.content.tLow}}；</p>
          <p>{{showLang('com.lighting.T.max')}}：{{cmdContent.content.tHigh}}；</p>
          <p>{{showLang('com.ins.escalation.heartbeat')}}：{{cmdContent.content.interval}}min；</p>
        </div>
        <div slot="footer"></div>
      </Modal>
    </template>
    <ModalLightArgs v-model="showLightArgModal" :command="cmdItem" @saved="lightArgsSaved" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import detailsLamp from './detailsLamp'
import lampMap from '@/views/light/road/gis/mapNew/Index'
import ModalLightArgs from '@/components/control/light/args/ModalArgEdit'
import ModalCheckAuth from '@/views/light/road/monitor/station/control/ModalCheckAuth'
export default {
  name: 'StationBaseIndex',
  components: {
    detailsLamp,
    ModalLightArgs,
    ModalCheckAuth,
    lampMap
  },
  props: {

  },
  data() {
    return {
      curTab: 0,
      types: { 101: '市电常规型', 102: '市电太阳能混合', 103: '市电带倾斜', 104: '双色温', 105: '485单灯' },
      tabHeight: 200,
      siteName: [],
      showLamp: false,
      showLightArgModal: false,
      cmdItem: {},
      showLampList: [],
      showLampData: {},
      list: [],
      spanCols: ["name", "online", "tc", "po", "fireTime", 'result', 'lu', 'id', 'start',],
      cmdContent: {},
      showCmdContent: false,
      lights: [],
      mergeData: [],
      dataRefresh: 0,
      deviceArray: [
        { name: "全部", type: "all" },
        { name: "单灯", type: "light" },
      ],
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      cmdResults: {},
      timer: null,
      spanFields: ['seq', 'checkbox', 'name', 'code', 'online', 'q', 'sun', 'tc', 'lampType', 'areaName', 'fireTime', 'lu', 'result', 'gx', 'gy', 'gz', 'lampDetails'],
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedTypes: function () {
      let types = [];
      for (let l of this.lights) {
        if (types.indexOf(l.content.type) == -1) {
          types.push(l.content.type);
        }
      }
      return types;
    },
    colCanShow: function () {
      return function (code) {
        return this.config.lightShowColumns.indexOf(code) != -1;
      };
    },
  },
  watch: {
    curTab() {
      this.initList();
    },
    selectedNode() {
      this.initList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.types = { 101: this.showLang('com.device.lamp.101'), 102: this.showLang('com.device.lamp.102'), 103: this.showLang('com.device.lamp.103'), 104: this.showLang('com.device.lamp.104'), 105: this.showLang('com.device.lamp.105') },
      this.deviceArray = [{ name: this.showLang('com.state.all'), type: "all" }, { name: this.showLang('com.device.lamp'), type: "light" },]
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initList();
    window.eventBus.$on('paramStateComing', params => {
      let ls = this.list.filter(p => p.lid == params.id);
      for(let l of ls){
        this.$set(l, 'online', params.online);
        this.$set(l, 'alarm', params.alarm);
        this.$set(l, 'running', params.running);
      }
    });
    window.eventBus.$on('paramRealDataComing', params => {
      let ls = this.list.filter(p => p.lid == params.paramId);
      for (let l of ls) {
        this.$set(l, 'fireTime', new Date(params.data.time).format('yyyy-MM-dd HH:mm:ss'));
        this.$set(l, 'tc', params.data.tc);
        this.$set(l, 'tm', params.data.tm);
        this.$set(l, 'start', `${params.data.sh}:${params.data.sm}`);
        this.$set(l, 'po', params.data.po);
        this.$set(l, 'lo', params.data[`lo`]);
        this.$set(l, 'rssi', params.data[`rssi`]);
        let uv = 0;
        if(l.ch == 1){
          uv = this.img.val(params.data.u, 2);
        }else{
          uv = this.img.val(params.data[`u${l.ch}`], 2);
        }
        this.$set(l, 'u', uv);
        this.$set(l, 'u', params.data.u.toFixed(2));
        this.$set(l, 'op', params.data[`op${l.ch}`]);
        this.$set(l, 'oc', params.data[`oc${l.ch}`]);
        this.$set(l, 'ct', params.data[`ct${l.ch}`]);
        this.$set(l, 'w', params.data[`w${l.ch}`]);
        this.$set(l, 'p', params.data[`p${l.ch}`]);
        this.$set(l, 'q', params.data[`q1`]);
        this.$set(l, 'sun', params.data[`sun`]);
        this.$set(l, 'c', params.data[`c${l.ch}`]);
        this.$set(l, 'cl', params.data[`cl${l.ch}`]);
        this.$set(l, 'gx', params.data[`gx${l.ch}`]);
        this.$set(l, 'gy', params.data[`gy${l.ch}`]);
        this.$set(l, 'gz', params.data[`gz${l.ch}`]);
        this.$set(l, 'lu', params.data[`lu`]);
      }
    });
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramStateComing');
    window.eventBus.$off('paramCommandComing');
    window.eventBus.$off('paramRealDataComing');
    clearInterval(this.timer);
  },
  methods: {
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.list.filter(p => p._cmdId == cmdId);
        if (ds.length == 0) {
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], '_status', item.status);
          this.$set(ds[0], '_message', item.message);
          this.$set(ds[0], '_content', item.content);
          this.$set(ds[0], '_message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
          // this.spanData();
        }
      }
      // this.calcSuccessRate();
    },
    cmdAuthUserChecked: function(params){
      if(this.checkAuthModalSuccessFunction){
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function(type, cmdCode, isOpen, checkOkFun){
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, {type, code: cmdCode, open: isOpen}).then(res => {
        if(res.code != 0)return;
        if(!res.data.need){
          checkOkFun({userId: 0});
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    lightArgsSaved: function(params){
        this.showLightArgModal = false;
      this.checkCmdAuth('light', params.paramsCode, false, (checkResult) => {
        this.showArgModal = false;
        if (this.clearOldLog) {
          for (let item of this.list) {
            // item.result = '';
            // item.status = 0;
            item._message = '';
            item._status = 0;
          }
        }
        let chks = this.$refs.table.getCheckboxRecords();
        let cmds = []
        let ds = {};
        let ns = [];
        let code = params.code;
        if (params.code == 'openSingle' || params.code == 'closeSingle') {
            code = 'handSingle';
          }
        for (let chk of chks) {
          ns.push(chk.code);
          ds[chk.name] = chk.topId;
          chk._message = '';
          chk._status = 0;
          // let args = this.clone(params.args);// {channel: chk.channel}
          // if (params.code == 'openSingle' || params.code == 'closeSingle') {
          //   code = 'handSingle';
          //   args.change = true;
          // } else if (params.code == 'handControl') {
          //   args.point = false;
          // } else if (params.code == 'setYearPlan') {
          //   args.timeId = chk.timeId;
          // } else if (params.code == 'update') {
          //   args.flag = 1;
          // }
          cmds.push(chk.lid);
          // cmds.push({ id: chk.id, code: code, name: params.name, paramId: chk.lid, ctype: chk.connectType, deviceId: chk.devId, checkUserId: checkResult.userId, args: args });
        }
        var cmd = {
          code: code,
          list: cmds,
          args: params.args,
          checkUserId: checkResult.userId
        }
        if (params.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device', ns),
            onOk: async () => {
              this.sendLightCommand(cmd);
            },
          });
        } else {
          this.sendLightCommand(cmd);
        }
      });
    },
    doCommand: function(params){
      let list = this.$refs.table.getCheckboxRecords();
      if (list.length == 0) {
        this.$Modal.error({
          title: this.showLang('com.ins.arrange.wrong'),
          content: this.showLang('com.ins.select.lamp.controller'),
        });
        return;
      }
      if (params.needArgs || params.needPswd) {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showLightArgModal = true;
      } else {
        this.checkCmdAuth('light', params.code, false, (checkResult) => {
          this.showLightArgModal = false;
          // let cmds = [], cmdIds = [];
          let cmd1 = {
            code: params.code,
            list: [],
            args: {},
            checkUserId: checkResult.userId
          }
          for (let l of list) {
            cmd1.list.push(l.lid);
            // if (cmds.filter(p => p.deviceId == l.pid).length > 0) continue;
            // l.result = '';
            // l.content = {};
            // l.status = 0;
            // cmdIds.push(l.id);
            // cmds.push({ id: l.id, code: cmd.code, name: cmd.name, deviceId: l.devId, checkUserId: checkResult.userId, args: {}, source: "lighting" });
          }
          this.sendLightCommand(cmd1);
        });
      }
    },
    isLightOn: function(params){
      for(let i = 1; i <= 4; i++){
        if(params[`current${i}`] && params[`current${i}`] > 0)return true;
      }
      return false;
    },
    getStateImage: function(params){
      //running  亮灯 灭灯  2
      //online  在线 离线 1
      //alarm  报警 不报警  2 
      if(!params.row.online){
        return this.img.tree.station.offliner
      }else{
        if(params.row.alarm){
          if(params.row.running){
            return this.img.tree.station.alarmOn
          }else{
            return this.img.tree.station.alarmOff
          }
        }else{
          if(params.row.running){
            return this.img.tree.station.onlineOn
          }else{
            return this.img.tree.station.onlineOff
          }
        }
      }
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.table.reloadRow(this.list, null, rowIndex)
    },
    spanData() {
      if(this.list.length <= 1)return;
      let mergeData = [];
      let cols = this.$refs.table.getColumns();
      let mergeCount = 1, row = 0;
      for (let i = 0; i < this.list.length - 1; i++) {
        if(this.list[i].lid == this.list[i + 1].lid){
          mergeCount ++;
        }else{
          for(let c = 0; c < cols.length; c++){
            let col = cols[c];
            if(this.spanFields.indexOf(col.field) != -1){
              mergeData.push({ row: row, col: c, rowspan: mergeCount, colspan: 1 })
            }
          }
          row += mergeCount;
          mergeCount = 1;
        }
      }
      if(mergeCount > 1){
        for(let c = 0; c < cols.length; c++){
          let col = cols[c];
          if(this.spanFields.indexOf(col.field) != -1){
            mergeData.push({ row: row, col: c, rowspan: mergeCount, colspan: 1 })
          }
        }
      }
      this.$set(this, 'mergeData', mergeData);
    },
    showDetails: function (cmd) {
      this.cmdContent = cmd;
      this.showCmdContent = true;
    },
    setCmdContent: function (id, cmd, message) {
      let ls = this.list.filter(p => p.id == id);
      if (ls.length == 0) return;
      let its = this.list.filter(p => p.lid == ls[0].lid);
      for (let it of its) {
        this.$set(it, 'status', cmd.status)
        this.$set(it, 'result', message)
        this.$set(it, 'cmd', cmd.code)
        this.$set(it, 'content', cmd.content)
        this.$set(it, 'args', cmd.args)
        if (cmd.status == 9) {
          this.$set(it, 'online', true);
          if (cmd.code == 'forceRead') {
            this.$set(it, 'running', this.isLightOn(cmd.content));
            let cv = 0;
            if(cmd.content[`current${it.ch}`]){
              cv = cmd.content[`current${it.ch}`].toFixed(2)
            }
            this.$set(it, 'c', cv);
            this.$set(it, 'cl', cmd.content[`leakage${it.ch}`]);
            this.$set(it, 'ct', cmd.content[`relayTime${it.ch}`]);
            this.$set(it, 'oc', cmd.content[`outColor${it.ch}`]);
            this.$set(it, 'op', cmd.content[`outRate${it.ch}`]);
            this.$set(it, 'p', cmd.content[`power${it.ch}`]);
            this.$set(it, 'po', cmd.content[`powerOnTime`]);
            this.$set(it, 'lo', cmd.content[`lightOnTime`]);
            this.$set(it, 'rssi', cmd.content[`rssi`]);
            this.$set(it, 'q', cmd.content[`quantity1`]);
            this.$set(it, 'sun', cmd.content[`sunEnergy`]);
            this.$set(it, 'start', `${cmd.content.startHour}:${cmd.content.startMinute}`);
            this.$set(it, 'tc', cmd.content.tempChip);
            this.$set(it, 'tm', cmd.content.tempModule);
            let uv = 0;
            if(it.ch == 1){
              uv = this.img.val(cmd.content.voltage, 2);
            }else{
              uv = cmd.content[`voltage${it.ch}`]
            }
            this.$set(it, 'u', this.img.val(uv, 2));
            // this.$set(it, 'u', cmd.content.voltage.toFixed(2));
            this.$set(it, 'w', cmd.content[`workTime${it.ch}`]);
            this.$set(it, 'fireTime', new Date(cmd.content.time).format('yyyy-MM-dd HH:mm:ss'));
            this.$set(it, 'gx', cmd.content.gyrox);
            this.$set(it, 'gy', cmd.content.gyroy);
            this.$set(it, 'gz', cmd.content.gyroz);
            this.$set(it, 'lu', this.img.val(cmd.content.leakageVol, 2));
          }
        }
      }
      this.spanData();
    },
    getSelectedItems: function () {
      return this.$refs.table.getCheckboxRecords();
    },
    lampDetails(el) {
      this.showLampList = this.list.filter(p => p.lid == el.lid);
      let lightsId= this.lights.filter(p => p.id == el.lid);
      let list = this.selectedStations;
      this.siteName = list[0].name;
      this.showLamp = true;
      this.showLampData = el;
      this.showLampData.content = lightsId[0].content;

    },
    setTableHeight: function () {
      if (this.$refs.table) {
        this.tabHeight = this.$refs.table.clientHeight - 5;
      }
    },
    initList: function () {
      if (this.selectedNode.type != this.productCodes.station || this.selectedNode.id <= 0) {
        this.$set(this, 'lights', []);
        this.$set(this, 'list', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLight`, { stationId: this.selectedNode.id }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'lights', res.data);
          let devs = [];
          for (let l of res.data) {
            for (let i = 1; i <= 4; i++) {
              if (!l.content[`en${i}`]) continue;
              let uv = 0;
              if(i == 1){
                uv = l.lastData && typeof (l.lastData.u) == 'number' && l.lastData.u >= 0 ? l.lastData.u.toFixed(2) : '-';
              }else{
                uv = l.lastData && typeof (l.lastData[`u${i}`]) == 'number' && l.lastData[`u${i}`] >= 0 ? l.lastData[`u${i}`] : '-';
              }
              devs.push({
                id: `l${l.id}c${i}`,
                lid: l.id,
                name: l.name,
                code: l.code,
                devId: l.deviceId,
                online: l.online,
                alarm: l.alarm,
                running: l.running,
                fireTime: l.fireTime == 0 ? '-' : new Date(l.fireTime).format('yyyy-MM-dd HH:mm:ss'),
                tc: l.lastData && typeof (l.lastData.tc) == 'number' && l.lastData.tc >= 0 ? l.lastData.tc : '-',
                tm: l.lastData && typeof (l.lastData.tm) == 'number' && l.lastData.tm >= 0 ? l.lastData.tm : '-',
                start: l.lastData && typeof (l.lastData.sh) == 'number' && l.lastData.sh >= 0 ? `${l.lastData.sh}:${l.lastData.sm}` : '-',
                po: l.lastData && typeof (l.lastData.po) == 'number' && l.lastData.po >= 0 ? l.lastData.po : '-',
                lo: l.lastData && typeof (l.lastData.lo) == 'number' && l.lastData.lo >= 0 ? l.lastData.lo : '-',
                rssi: l.lastData && typeof (l.lastData.rssi) == 'number' && l.lastData.rssi >= 0 ? l.lastData.rssi : '-',
                u: uv,
                ch: i,
                n: l.content[`nm${i}`],
                op: l.lastData && typeof (l.lastData[`op${i}`]) == 'number' && l.lastData[`op${i}`] >= 0 ? l.lastData[`op${i}`] : '-',
                oc: l.lastData && typeof (l.lastData[`oc${i}`]) == 'number' && l.lastData[`oc${i}`] >= 0 ? l.lastData[`oc${i}`] : '-',
                ct: l.lastData && typeof (l.lastData[`ct${i}`]) == 'number' && l.lastData[`ct${i}`] >= 0 ? l.lastData[`ct${i}`] : '-',
                w: l.lastData && typeof (l.lastData[`w${i}`]) == 'number' && l.lastData[`w${i}`] >= 0 ? l.lastData[`w${i}`] : '-',
                p: l.lastData && typeof (l.lastData[`p${i}`]) == 'number' && l.lastData[`p${i}`] >= 0 ? l.lastData[`p${i}`] : '-',
                q: l.lastData && typeof (l.lastData[`q1`]) == 'number' && l.lastData[`q1`] >= 0 ? l.lastData[`q1`] : '-',
                sun: l.lastData && typeof (l.lastData[`sun`]) == 'number' && l.lastData[`sun`] >= 0 ? l.lastData[`sun`] : '-',
                c: l.lastData && typeof (l.lastData[`c${i}`]) == 'number' && l.lastData[`c${i}`] >= 0 ? l.lastData[`c${i}`].toFixed(2) : '-',
                cl: l.lastData && typeof (l.lastData[`cl${i}`]) == 'number' && l.lastData[`cl${i}`] >= 0 ? l.lastData[`cl${i}`] : '-',
                gx: l.lastData && typeof (l.lastData[`gx`]) == 'number' && l.lastData[`gx`] >= 0 ? l.lastData[`gx`] : '-',
                gy: l.lastData && typeof (l.lastData[`gy`]) == 'number' && l.lastData[`gy`] >= 0 ? l.lastData[`gy`] : '-',
                gz: l.lastData && typeof (l.lastData[`gz`]) == 'number' && l.lastData[`gz`] >= 0 ? l.lastData[`gz`] : '-',
                lu: l.lastData && typeof (l.lastData.lu) == 'number' && l.lastData.lu >= 0 ? l.lastData.lu.toFixed(2) : '-',
                status: 0,
                content: {},
                timeName: l.timeName,
                result: '',
                _cmdCode: '',
                _cmdId: '',
                _status: 0,
                _message: '',
                _content: {}
              });
            }
          }
          devs.sort((a, b) => {
            if (a.name == b.name) return 0;
            return a.name > b.name ? 1 : -1;
          })
          this.$set(this, 'list', devs);
          this.dataRefresh++;
          this.spanData();
          // if (this.curTab >= 0 && this.$refs.table) {
          //   this.$refs.table.toggleAllSelection()
          // }
        }
      });
    },
    getCmdStatus: function (cmd) {
      switch (cmd._status) {
        case 0: return `${cmd._message}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 3: return cmd._message;
        case 4: return this.showLang('com.ins.execution') + `：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd._message}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd._message}`;
        case 9:
          switch (cmd._cmdCode) {
            case 'getChipVoltage': return `通道号：${cmd._content.ch}；电压系数：${cmd._content.v}`;
            case 'getChipCurrent': return `通道号：${cmd._content.ch}；电流系数：${cmd._content.v}`;
            case 'getChipPower': return `通道号：${cmd._content.ch}；电流系数：${cmd._content.v}`;
            case 'getSigFlag': return +`${this.showLang('com.ins.success')}:${this.showLang('com.op.open')}：${cmd._content.on}，${this.showLang('com.op.shut')}：${cmd._content.off}`;
            case 'getAPN': return `APN:${cmd._content.apn}, user: ${cmd._content.user}, pswd: ${cmd._content.pswd}`;
            case 'getclock': return this.showLang('com.ins.success')+`：${cmd._content.nowTime}`;
            case 'getGroup': return this.showLang('com.ins.success')+`：${this.showLang('com.group.number')} ${cmd._content.no}`;
            case 'getInfo':{
              let result = '';
              if(cmd._content.appcode){
                result += `应用编号：${cmd._content.appcode}；`
              }
              if(cmd._content.appversion){
                result += `应用版本：${cmd._content.appversion}；`
              }
              if(cmd._content.hardware){
                result += `硬件版本：${cmd._content.hardware}；`
              }
              if(cmd._content.software){
                result += `软件版本：${cmd._content.software}；`
              }
              if(cmd._content.manufacture){
                result += `生产厂商：${cmd._content.manufacture}；`
              }
              return result;
              // this.showLang('com.site.hw')+`：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture || '-'}`;
            }
            // case 'getInfo': return this.showLang('com.site.hw')+`：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd._content.iccid}，IMEI：${cmd._content.imei}，RSSI：${cmd._content.rssi}`;
            case 'getChipVolCurArgs': return this.showLang('cmd.result.chip.vol.cur', cmd._content.vol1, cmd._content.vol2, cmd._content.leakVol, cmd._content.cur1, cmd._content.cur2, cmd._content.leakCur);
            case 'getDayPlan1': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd._content[n];
                if (!item) continue;
                str += this.showLang('com.date.period')+`${n + 1}，${this.showLang('com.date.time')}：${item.min}，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.light')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.color')}：${color}，`;
                  }
                }
                str += "\n";
                // str += `时段${n+1}：时间：${item.min}分,1通道亮度：${item.ch1}，1通道色温：${item.c1},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};`
              }
              return str;
            }
            case 'getDayPlan2': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd._content[n];
                if (!item) continue;
                str += this.showLang('com.date.period')+`${n + 1}，${this.showLang('com.date.time')}：${item.min}，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                   str += `${c + 1}${this.showLang('com.ins.ch.light')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.color')}：${color}，`;
                  }
                }
                str += "\n";
                // str += `时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；时间：${cmd.content[`start1${n}`]},1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};`
              }
              return str;
            }
            case 'getPlanType': {
              switch (cmd._content.type) {
                case 0: return this.showLang('com.but.timing');
                case 1: return this.showLang('com.but.ontime');
                case 2: return this.showLang('com.but.mode.light');
                case 3: return this.showLang('com.but.mode.sigle');
                case 255: return this.showLang('com.but.day.table.no');
                default: return this.showLang('com.but.timing');
              }
            }
            case 'getNetwork': return `IP:${cmd._content.host}, ${this.showLang('com.ins.port')}:${cmd._content.port}, ${this.showLang('com.ins.heartbeat')}:${cmd._content.heart}s`
            case 'getLuxOpenValue': return `${this.showLang('com.ins.light.val')}:${cmd._content.lux}`
            case 'getAlarmStatus': {
              return `${this.showLang('com.lighting.V.alarm')}:${cmd._content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.lighting.I.alarm')}：${cmd._content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.lighting.T.alarm')}：${cmd._content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.gyro.police')}：${cmd._content.gyro ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.leac.police')}：${cmd._content.leac ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.leav.police')}：${cmd._content.leav ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`
            }
            case 'getLeakageAlarmLevel': {
              return `${this.showLang('com.stat.level1')}：${cmd._content.l1}; ${this.showLang('com.stat.level2')}：${cmd._content.l2}; ${this.showLang('com.stat.level3')}：${cmd._content.l3}`;
            }
            case 'getLeakageAlarmEnable': {
              return `${this.showLang('com.state')}：${cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}; ${this.showLang('com.date.check')}：${cmd._content.check}; ${this.showLang('com.ins.record.num')}：${cmd._content.count}`;
            }
            case 'getLeakageAlarmStatus': {
              return `${this.showLang('com.state')}：${this.leakagAlarmeStatus[cmd._content.level]}; ${this.showLang('com.ins.cl.val')}：${cmd._content.val};`;
            }
            case 'getUnionParam': {
              return `
              <p>${this.showLang('com.lighting.delay.lamp1')}：${cmd._content.keep1}s</p>
              <p>${this.showLang('com.lighting.delay.lamp2')}：${cmd._content.keep2}s</p>
              <p>${this.showLang('com.lighting.is.sensing')}：${cmd._content.sensor == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.is.signal')}：${cmd._content.txen > 10 ? this.showLang('com.state.effective') :this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.send.cycle')}：${cmd._content.period}s</p>
              <p>${this.showLang('com.lighting.linkage.number')}：${cmd._content.rfsn}；</p>
              <p>433${this.showLang('com.lighting.send.power')}：${cmd._content.txpower}；</p>
              <p>433${this.showLang('com.lighting.channel')}：${cmd._content.channel}；</p>
              <p>${this.showLang('com.lighting.response.range')}：${cmd._content.range}；</p>
              <p>${this.showLang('com.lighting.is.put.signal')}：${cmd._content.rxen == 1 ?this.showLang('com.state.receive') : this.showLang('com.state.no.receive') }；</p>
              <p>${this.showLang('com.lighting.linkage.mode')}：${cmd._content.mode == 1 ? this.showLang('com.lighting.Linkage.control') : this.showLang('com.lighting.lamp.contact')}；</p>
              <p>${this.showLang('com.lighting.repeat.num')}：${cmd._content.repeat}；</p>
              `
            }
            case 'get433Args': {
              return `
              1路亮灯延时：${cmd._content.delay1}s
              2路亮灯延时：${cmd._content.delay2}s
              是否允许传感器检测：${cmd._content.senseEn == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')}；
              检测灵敏度：${cmd._content.senseLevel}s
              检测到后是否允许发送：${cmd._content.txReqEn == 1 ?this.showLang('com.state.effective'):this.showLang('com.state.invalid')};
              检测到后发送数量：${cmd._content.txReqCnt}；
              发送周期：${cmd._content.txDuty}；
              发送不响应期：${cmd._content.txSleep}；
              是否允许接收信号：${cmd._content.rxReqEn == 1 ?this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}；
              发送功率：${cmd._content.txPower}；
              信道：${cmd._content.channel}；
              控制的范围：${cmd._content.range}；
              `
            }
            case 'get433Plan': {
              return `
              一路亮度1：${cmd._content.bright11};
              一路色温1：${cmd._content.color11};
              一路亮度2：${cmd._content.bright12};
              一路色温2：${cmd._content.color12};
              一路亮度3：${cmd._content.bright13};
              一路色温3：${cmd._content.color13};
              一路亮度4：${cmd._content.bright14};
              一路色温4：${cmd._content.color14};
              一路亮度5：${cmd._content.bright15};
              一路色温5：${cmd._content.color15};
              一路亮度6：${cmd._content.bright16};
              一路色温6：${cmd._content.color16};
              二路亮度1：${cmd._content.bright21};
              二路色温1：${cmd._content.color21};
              二路亮度2：${cmd._content.bright22};
              二路色温2：${cmd._content.color22};
              二路亮度3：${cmd._content.bright23};
              二路色温3：${cmd._content.color23};
              二路亮度4：${cmd._content.bright24};
              二路色温4：${cmd._content.color24};
              二路亮度5：${cmd._content.bright25};
              二路色温5：${cmd._content.color25};
              二路亮度6：${cmd._content.bright26};
              二路色温6：${cmd._content.color26};
              `
            }
            case 'get433Id': {
              return `ID: ${cmd._content.id}`;
            }
            case 'getSNID': {
              return `SNID: ${cmd._content.snid}`;
            }
            case 'getRelayLevel': {
              return `${cmd._content.level}`;
            }
            case 'getAlarmRange': {
              return this.showLang('cmd.msg.alarm.range', cmd._content.interval, cmd._content.vLow, cmd._content.vHigh, cmd._content.cLow, cmd._content.cHigh, cmd._content.tLow, cmd._content.tHigh, cmd._content.leac, cmd._content.leav);
              // return `间隔时间：${cmd.content.interval}分，电压范围：${cmd.content.vLow} - ${cmd.content.vHigh} V，电流范围：${cmd.content.cLow} - ${cmd.content.cHigh} A，温度范围：${cmd.content.tLow} - ${cmd.content.tHigh} ℃`
            }
            case 'getAdjustParams': return `${this.showLang('com.lighting.V.coefficient')}:${cmd._content.u}, ${this.showLang('com.lighting.I.coefficient')}:${cmd._content.i}, ${this.showLang('com.lighting.P.coefficient')}:${cmd._content.p}`;
            case 'getLatLng': return `${this.showLang('com.but.gis.get.result', cmd._content.lng, cmd._content.lat)}`;
            case 'getLatLngArgs': {
              let result = this.showLang('com.ins.ch') + cmd._content.channel + ',';
              result += (cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')) + ',\n';
              let i = 1;
              for(let item of cmd._content.list){
                result += this.showLang('com.date.time') + i + ', ' 
                  + this.showLang('com.lighting.op') + ': ' + item.bright + ', ' 
                  + this.showLang('com.lighting.oc') + ': ' + item.color + ','
                  + this.showLang('com.date.delayed') + ': ' + item.delay + this.showLang('unit.time.min') + '; \n';
                i++;
              }
              return `${this.showLang('com.but.gis.args.get.result', result)}`;
            }
            default: return this.showLang('com.ins.success');
          }
      }
    },
    getCmdStatus1: function (cmd) {
      switch (cmd.status) {
        case 0: return this.showLang('com.ins.create');
        case 1: return this.showLang('com.ins.wait');
        case 2: return this.showLang('com.ins.timeout');
        case 3: return this.showLang('com.ins.fail') + ":" + cmd.result;
        case 4: return this.showLang('com.ins.execution') + `：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd.result}`;
        case 9:
          switch (cmd.cmd) {
            case 'getclock': return this.showLang('com.ins.success') + "：" + cmd.content.nowTime;
            case 'getGroup': return this.showLang('com.ins.success') + "：" + this.showLang('com.group.number') + cmd.content.no;
            case 'getInfo':{
              let result = '';
              if(cmd.content.appcode){
                result += `应用编号：${cmd.content.appcode}；`
              }
              if(cmd.content.appversion){
                result += `应用版本：${cmd.content.appversion}；`
              }
              if(cmd.content.hardware){
                result += `硬件版本：${cmd.content.hardware}；`
              }
              if(cmd.content.software){
                result += `软件版本：${cmd.content.software}；`
              }
              if(cmd.content.manufacture){
                result += `生产厂商：${cmd.content.manufacture}；`
              }
              return result;
              // this.showLang('com.site.hw')+`：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture || '-'}`;
            }
            // case 'getInfo': return this.showLang('com.site.hw') + "：" + cmd.content.hardware + "，" + this.showLang('com.site.sw') + "：" + cmd.content.software + "，" + this.showLang('com.site.sw') + "：" + cmd.content.manufacture;
            case 'getSimInfo': return `ICCID：${cmd.content.iccid}，IMEI：${cmd.content.imei}，RSSI：${cmd.content.rssi}`;
            case 'getDayPlan1': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd.content[n];
                if (!item) continue;
                str += this.showLang('com.date.period') + `${n + 1}，${this.showLang('com.date.time')}：${item.min}min，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.light')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.color')}：${color}，`;
                  }
                }
              }
              return str;
            }
            case 'getDayPlan2': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd.content[n];
                if (!item) continue;
                str += this.showLang('com.date.period') + `${n + 1}，${this.showLang('com.date.time')}：${item.min}，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.light')}：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}${this.showLang('com.ins.ch.color')}：${color}，`;
                  }
                }
              }
              return str;
            }
            case 'getPlanType': {
              switch (cmd.content.type) {
                case 0: return this.showLang('com.but.timing');
                case 1: return this.showLang('com.but.ontime');
                case 2: return this.showLang('com.but.mode.light');
                case 255: return this.showLang('com.but.day.table.no');
                default: return this.showLang('com.but.timing');
              }
            }
            case 'getNetwork': return `IP:${cmd.content.host}, ${this.showLang('com.ins.port')}:${cmd.content.port}, ${this.showLang('com.ins.heartbeat')}:${cmd.content.heart}s`
            case 'getLuxOpenValue': return this.showLang('com.ins.light.val') + `:${cmd.content.lux}`
            case 'getGyroLevel': return ` ${this.showLang('com.but.tiltThreshold')}，${this.showLang('com.data.x')}：${cmd.content.lx}，${this.showLang('com.data.y')}：${cmd.content.ly}，${this.showLang('com.data.z')}：${cmd.content.lz}`
            default: return this.showLang('com.ins.success');

          }
      }
    },
    viewDetail: function (params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.row.station.id, type: this.productCodes.station });
    },
    switchTab: function (name) {
      // this.spin.show = true;
      this.curTab = name;
    },
    switchStyle: function (name) {
      this.lightStyle = name;
    },
    sendLightCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendLampOld`, cmd).then(res => {
        if (res.code == 0) {
          for (let item of res.data.list) {
            let ds = this.list.filter(p => p.lid == item.id);
            if (ds.length == 0) continue;
            this.$set(ds[0], '_cmdCode', cmd.code);
            this.$set(ds[0], '_cmdId', item.success ? item.message : '');
            this.$set(ds[0], '_status', item.success ? 1 : 8);
            this.$set(ds[0], '_content', {});
            this.$set(ds[0], '_message', item.success ? '指令已进入发送队列' : item.message);
            // this.spanData();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.base-light-container {
  background-color: white;
}
.light-data-query {
  flex: none;
  display: flex;
  align-items: center;
}
.light-data-area {
  flex: auto;
  margin-top: 3px;
  padding-bottom: 60px;
}
.light-data-tab {
  padding: 0 5px;
  margin: 3px 3px 0 3px;
  font-family: Microsoft YaHei;
  color: #021629;
  box-sizing: border-box;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}
.tab-active::after {
  content: "";
  display: block;
  margin: -5px auto 0;
  border-bottom: 4px solid #3880fc;
  border-radius: 2px;
}
.tab-active {
  font-size: 16px;
}
.box-button-line {
  width: 20px;
  height: 1px;
  flex: none;
  background: #c0c5cb;
}
.device-type-switch {
  position: fixed;
  top: 240px;
  right: 70px;
  z-index: 15;
  /* width: 100px;
  height: 100px; */
}
.box-content {
  position: absolute;
  top: 110px;
  bottom: 10px;
  right: 0;
  left: 310px;
  background-color: #e3e9ef;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 300;
  border: solid 1px red;
  overflow: hidden;
}
.box-body {
  height: 0;
  flex: auto;
  margin-top: 5px;
  /* background-color: #FFFFFF; */
  /* display: flex; */
  /* flex-direction: column; */
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
}
.style-content {
  flex: none;
  display: flex;
  padding: 0 195px 0 30px;
  margin-bottom: 20px;
}
.box-button-list {
  position: absolute;
  right: 0;
  top: 130px;
  width: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px rgba(56, 128, 252, 0.5);
  border-radius: 12px 0px 0px 12px;
}
.box-button-item {
  /* margin-top: 8px; */
  flex: none;
  width: 37px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.box-button-item img {
  width: 14px;
  height: 14px;
  /* margin-top: 10px; */
}
.box-button-item span {
  width: 18px;
  margin: 3px 0 0 6px;
  line-height: 14px;
  font-size: 14px;
  color: #324252;
  font-weight: 400;
}
.box-button-active span {
  color: #3880fc;
}
</style>