<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px" :styles="{top: '50px'}">
    <div slot="header">{{showLang('com.op.edit.info')}}</div>
    <div style="height: 580px; overflow-y:auto">
      <Form ref="form" :model="action" class="form" :label-width="120">
        <FormItem prop="start" :label="showLang('com.date.range')" v-if="editType == 1">
          <DatePicker type="date" v-model="action.start" format="MM-dd" :placeholder="showLang('com.date.start')" style="width: 155px"></DatePicker>
          {{showLang('com.date.to')}}
          <DatePicker type="date" v-model="action.end" format="MM-dd" :placeholder="showLang('com.date.end')" style="width: 155px"></DatePicker>
          <span style="color:red;margin-left:10px">{{showLang('com.date.update.plan')}}</span>
        </FormItem>
        <FormItem prop="start" :label="showLang('com.date.current2')" v-else-if="editType == 0">
          {{month+1}}{{showLang('com.date.month')}}{{editDay}}{{showLang('com.date.sun')}}
        </FormItem>
        <FormItem prop="start" :label="showLang('com.current')" v-else>
          {{weeks[editDay - 1]}}
        </FormItem>
        <FormItem prop="start" :label="showLang('com.op.edit.type')" v-if="editType == 1">
          <div style="display:flex;align-items:center;">
            <i-switch v-model="gpsEdit" size="large" true-color="blue" false-color="green" :title="showLang('com.date.longitude.schedule')">
              <span slot="open">{{showLang('timetable.longitude')}}</span>
              <span slot="close">{{showLang('timetable.constant.val')}}</span>
            </i-switch>
            <Input v-if="gpsEdit" type="number" v-model="form.lng" style="width: 210px; margin-left: 15px" disabled>
            <span slot="prepend">{{showLang('com.import.cn.lng')}}</span>
            </Input>
            <Input v-if="gpsEdit" type="number" v-model="form.lat" style="width: 210px; margin-left: 15px" disabled>
            <span slot="prepend">{{showLang('com.import.cn.lat')}}</span>
            </Input>
            <Input v-if="gpsEdit" v-model="form.city" style="width: 160px; margin-left: 15px" disabled>
            <span slot="prepend">{{showLang('cmd.data.area')}}</span>
            </Input>
            <Button v-if="gpsEdit" type="primary" style="margin-left: 15px" @click="selectPos">选择</Button>
          </div>
        </FormItem>
        <FormItem prop="start" :label="showLang('com.date.weekly.scope')" v-if="editType == 1">
          <div style="display:flex;align-items:center;">
            <CheckboxGroup v-model="cycle">
              <Checkbox label="1">{{showLang('date.Monday')}}</Checkbox>
              <Checkbox label="2">{{showLang('date.Tuesday')}}</Checkbox>
              <Checkbox label="3">{{showLang('date.Wednesday')}}</Checkbox>
              <Checkbox label="4">{{showLang('date.Thursday')}}</Checkbox>
              <Checkbox label="5">{{showLang('date.Friday')}}</Checkbox>
              <Checkbox label="6">{{showLang('date.Saturday')}}</Checkbox>
              <Checkbox label="0">{{showLang('date.Sunday')}}</Checkbox>
            </CheckboxGroup>
            {{showLang('Select.year')}}：
            <!-- <DatePicker type="year" placeholder="Select year" v-model="yearValue" style="width: 90px" :key='newValIdex'/> -->
            <Select :placeholder="showLang('save.select')" v-model="yearValue" style="width: 100px">
              <template v-for="(item, idx) in years">
                <Option :value="item" :key="idx">{{item}}</Option>
              </template>
            </Select>
          </div>
        </FormItem>
        <template v-if="!gpsEdit">
          <FormItem v-for="n in 4" :key="n" :label="showLang('com.op.action')+n">
            <TimePicker v-model="action['t'+n]" format="HH:mm" placeholder="Select time" style="width: 120px;margin-right:10px"></TimePicker>
            <Select :placeholder="showLang('save.select')" v-model="action['a'+n]" style="width: 100px">
              <template v-for="(v,k) in powerTableActions">
                <Option :value="parseInt(k)" :key="k">{{v}}</Option>
              </template>
            </Select>
            <Checkbox v-if="editType == 1" v-model="action['c'+n]" style="margin-left: 5px">
              {{showLang('timetable.update.group',n)}}</Checkbox>
          </FormItem>
        </template>
        <template v-else>
          <FormItem :label="showLang('com.look.data')">
            <DatePicker type="date" v-model="dateDay" format="MM-dd" :placeholder="showLang('com.date.start')" style="width: 155px" @on-change='dateDayChange'></DatePicker>
            {{showLang('com.date.sunrise')}}：<span class='red'>{{dateTime.time1}}</span>
            {{showLang('com.date.sunset')}}：<span class='red'>{{dateTime.time2}}</span>
          </FormItem>
          <FormItem v-for="n in 4" :key="n" :label="showLang('com.op.action')+n">
            <Select :placeholder="showLang('save.select')" v-model="action['r'+n]" style="width: 100px; float:left">
              <Option value="1">{{showLang('com.state.sunrise')}}</Option>
              <Option value="2">{{showLang('com.state.sunset')}}</Option>
            </Select>
            <Select :placeholder="showLang('save.select')" v-model="action['b'+n]" style="width: 100px; float:left;margin-left: 5px">
              <Option value="1">{{showLang('com.state.normal')}}</Option>
              <Option value="2">{{showLang('com.state.advance')}}</Option>
              <Option value="3">{{showLang('com.state.delay')}}</Option>
            </Select>
            <Input type="number" v-model="action['d'+n]" style="width: 100px; float:left;margin-left: 5px">
            <span slot="append">min</span>
            </Input>
            <Select :placeholder="showLang('save.select')" v-model="action['a'+n]" style="width: 100px; float:left;margin-left: 5px">
              <template v-for="(v,k) in powerTableActions">
                <Option :value="parseInt(k)" :key="k">{{v}}</Option>
              </template>
            </Select>
            <Checkbox v-model="action['c'+n]" style="margin-left: 5px">{{showLang('timetable.update.group',n)}}
            </Checkbox>
          </FormItem>
        </template>
      </Form>
      <ModalSelectMapPoint v-model="showMapModal" :custCenter="form" @posSelected="posSelected" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancelDayAction">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" @click="saveDayAction">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
export default {
  name: 'ModalDayAction',
  components: {
    ModalSelectMapPoint
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      weeks: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',],
      showModal: this.value,
      action: {},
      gpsEdit: true,
      cycle: ['0', '1', '2', '3', '4', '5', '6'],
      yearValue: '',
      years: [],
      editType: 0, // 0、单日年表，1、批量年表，2、单日周表
      editDay: 1,
      month: 0,
      form: {
        lng: 0,
        lat: 0,
        city: '',
      },
      dateDay: '01-01',
      dateTime: {
        time1: '',
        time2: '',
      },
      newValIdex: 0,
      showMapModal: false,
      powerTableActions: {
        0: '关闭',
        1: '开启',
        2: '无效'
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      this.newValIdex++;
      if (this.showModal) {
        this.form.lng = this.config.lng;
        this.form.lat = this.config.lat;
        this.form.city = this.config.area;
        this.editType = this.item.type;
        this.editDay = this.item.day;
        this.gpsEdit = this.editType == 1;
        this.month = this.item.month;
        this.action = this.item.action;
        this.yearValue = new Date().getFullYear();
        this.years.splice(0, this.years.length);
        this.years.push(this.yearValue - 2);
        this.years.push(this.yearValue - 1);
        this.years.push(this.yearValue);
        this.years.push(this.yearValue + 1);
        this.years.push(this.yearValue + 2);
        let currentDate = new Date();
        this.dateDay = `${currentDate.getMonth() + 1}-${currentDate.getDate()}`
        this.dateDayChange();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    this.weeks = [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')]
    this.powerTableActions = {
      0: this.showLang('com.op.close'),// '关闭'
      1: this.showLang('com.state.open'),// '开启',
      2: this.showLang('com.state.invalid'),// '无效'
    }
  },
  computed: {
    ...mapState('common', ['mapCenter']),
    ...mapState("auth", ["config",]),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  methods: {
    selectPos() {
      this.showMapModal = true;
    },
    posSelected(el) {
      this.form.lng = el.lng;
      this.form.lat = el.lat;
      this.form.city = el.city;
    },
    dateDayChange() {
      let day = new Date(this.dateDay).getDate();
      let month = new Date(this.dateDay).getMonth() + 1;
      let datea = new Date(`${this.yearValue}-${month}-${day}`);
      let riseAndSet = datea.sunRiseAndSet(this.form.lat, this.form.lng);
      this.dateTime.time1 = riseAndSet.rise;
      this.dateTime.time2 = riseAndSet.set;

    },
    cancelDayAction: function () {
      this.showModal = false;
    },
    saveDayAction: function () {
      // debugger;
      let args = { type: this.editType, month: this.month, gps: this.gpsEdit, gpsForm: this.form, day: this.editDay, action: this.action, cycle: this.cycle, yearValue: this.yearValue };
      // console.log('day action saved', args)
      this.$emit('dayActionSaved', args);
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>