import { render, staticRenderFns } from "./ModalDayAction.vue?vue&type=template&id=641c15f6&scoped=true"
import script from "./ModalDayAction.vue?vue&type=script&lang=js"
export * from "./ModalDayAction.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641c15f6",
  null
  
)

export default component.exports