<template>
  <Modal v-model="showModal" :mask-closable="false" width="700px">
    <div slot="header">{{command.name}}</div>
    <Form :model="form" :label-width="120">
      <FormItem :label="showLang('com.pswd.confirm')" v-if="needCheck">
        <div v-if="needCheck">
          <Input v-model="form.pswd" type="password" ref="pswd" style="width: 220px;height:32px;">
          <Button slot="append" icon="ios-eye" @click="eyeOutline()"></Button>
          </Input>
          <span style="color:red;margin-left:20px">*** {{showLang('com.pswd.user.confirm')}}</span>
        </div>
        <div v-else>{{showLang('com.pswd.lifespan')}}</div>
      </FormItem>
      <FormItem :label="showLang('com.tab.slogan')" v-if="['setComArgs', 'getComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.comname">
          <template v-for="(v, k) in comNames">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.but.dev.address.code')" v-if="['setComArgs1'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.address" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.comm.method')" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.comtype">
          <template v-for="n in comTypes">
            <Option :key="n" :value="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.BAUD')" v-if="['setComArgs', 'setComArgs1'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.combuad">
          <template v-for="n in comBuads">
            <Option :key="n" :value="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.data.bits')" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.comdata">
          <template v-for="n in comDatas">
            <Option :key="n" :value="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.check.bit')" v-if="['setComArgs', 'setComArgs1'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.comparity">
          <template v-for="n in comParitys">
            <Option :key="n" :value="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.stop.bit')" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.comstop">
          <template v-for="n in comStops">
            <Option :key="n" :value="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.minor.leakage.val')" v-if="['setLeakageRate'].indexOf(command.code) != -1">
        <Input v-model="form.ll" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.severe.leakage.val')" v-if="['setLeakageRate'].indexOf(command.code) != -1">
        <Input v-model="form.lh" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.threshold.leakage.val')" v-if="['setLeakageRate'].indexOf(command.code) != -1">
        <Input v-model="form.lx" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="网络类型" v-if="['setK5Network'].indexOf(command.code) != -1">
        <i-switch v-model="form.netType" size="large" style="margin:3px 10px;" true-color="green" false-color="blue">
          <span slot="open">静态</span>
          <span slot="close">动态</span>
        </i-switch>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.http')" v-if="['setK5Network', 'setNetwork', 'setDefaultNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.host" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.port')" v-if="['setNetwork', 'setDefaultNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.port" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.heartbeat.time')" v-if="['setNetwork', 'setDefaultNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.heart" type="number" style="width: 120px;height:32px;"><span slot="append">秒</span></Input>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.slaveHost')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.slaveHost" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.slavePort')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.slavePort" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.apnHost')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.apnHost" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.apnUser')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.apnUser" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.tab.ip.apnPswd')" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.apnPswd" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')" v-if="['setLoraWork','handAdjust'].indexOf(command.code) != -1">
        <!-- <Input v-model="form.channel" type="number" style="width: 120px;height:32px;"></Input> -->
        <Select :placeholder="showLang('save.select')" v-model="form.channel">
          <template v-for="n in 8">
            <Option :key="n" :value="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <!-- <FormItem :label="showLang('444444444444')速率" v-if="['setLoraWork'].indexOf(command.code) != -1">
        <Input v-model="form.speed" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem> -->
      <!-- <FormItem :label="showLang('444444444444')电流倍率" v-if="['setCurrentRate'].indexOf(command.code) != -1">
        <Input v-model="form.currentRate" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem> -->
      <FormItem :label="showLang('com.look.data')" v-if="['getYearPlan'].indexOf(command.code) != -1">
        <div style="display: flex;align-items:center;">
          <!-- <i-switch v-model="form.single" size="large" style="margin:3px 10px;" true-color="green" false-color="blue">
            <span slot="open">单日</span>
            <span slot="close">整月</span>
          </i-switch> -->
          <DatePicker type="date" v-model="form.date" format="yyyy-MM-dd"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.time.delay')" v-if="['handControl'].indexOf(command.code) != -1">
        <div v-if="form.expire" style="font-size: 40px; margin: 20px">
          {{new Date(form.expire).format('yyyy-MM-dd HH:mm:ss')}}
        </div>
        <div style="display: flex;align-items:center;">
          <i-switch v-model="form.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
            <span slot="open">{{showLang('com.but.open.lamp')}}</span>
            <span slot="close">{{showLang('com.but.close.lamp')}}</span>
          </i-switch>
          <DatePicker type="datetime" v-model="form.expire" style="width: 200px" size="large" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.time.delay.ok')" v-if="['handControl'].indexOf(command.code) != -1">
        <div v-if="form.expireConfirm" style="font-size: 40px; margin: 20px">
          {{new Date(form.expireConfirm).format('yyyy-MM-dd HH:mm:ss')}}
        </div>
        <div style="display: flex;align-items:center;padding-left: 76px;">
          <DatePicker type="datetime" v-model="form.expireConfirm" style="width: 200px" size="large" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.lamp.upgrade')" v-if="['setLightUpdate'].indexOf(command.code) != -1">
        <i-switch v-model="form.flag" size="large" style="margin:3px 10px;" true-color="green" false-color="blue">
          <span slot="open">{{showLang('com.state.enable')}}</span>
          <span slot="close">{{showLang('com.state.disable')}}</span>
        </i-switch>
      </FormItem>
      <FormItem :label="showLang('com.but.ota.save.file')" v-if="['setSaveOtaFile'].indexOf(command.code) != -1">
        <i-switch v-model="form.flag" size="large" style="margin:3px 10px;" true-color="green" false-color="blue">
          <span slot="open">{{showLang('com.state.enable')}}</span>
          <span slot="close">{{showLang('com.state.prohibit')}}</span>
        </i-switch>
      </FormItem>
      <FormItem :label="showLang('com.date.period.enable')" v-if="['setTimeEnable'].indexOf(command.code) != -1">
        <div style="display: flex;align-items:center;">
          <Checkbox v-model="form.timeTableSpan1">{{showLang('com.date.period')}}1</Checkbox>
          <Checkbox v-model="form.timeTableSpan2">{{showLang('com.date.period')}}2</Checkbox>
          <Checkbox v-model="form.timeTableSpan3">{{showLang('com.date.period')}}3</Checkbox>
          <Checkbox v-model="form.timeTableSpan4">{{showLang('com.date.period')}}4</Checkbox>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.tab.size.pack')" v-if="['update'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.packLen" type="number" number style="width: 120px; height: 32px">
          <span slot="append">{{showLang('com.unit.byte')}}</span>
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.upgrade.file')" v-if="['update', 'startSaveOtaFile'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept=".bin" :disabled="loading" />
          </div>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.channel.number')" v-if="['handGroup1', 'handGroup11', 'handGroup10', 'handAll', 'handAll1', 'handAll0', 'setallclock'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.channel" type="number" number style="width: 120px; height: 32px">
        </Input>
      </FormItem>
      <FormItem :label="showLang('com.group.number')" v-if="['setGroup', 'handGroup1', 'handGroup11', 'handGroup10'].indexOf(command.code) != -1" class="form-item">
        <!-- <Input v-model="form.groupNo" type="number" number style="width: 120px; height: 32px">
        </Input> -->
        <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="width: 123px;">
          <template v-for="(s, idx) in areas">
            <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.lighting.P')" v-if="['handAdjust'].indexOf(command.code) != -1">
        <Input v-model="form.power" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>

      <FormItem :label="showLang('com.lighting.op')+'1'" v-if="['handSingle', 'handGroup1', 'handAll'].indexOf(command.code) != -1">
        <Input v-model="form.outputPower1" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.op')+'2'" v-if="['handSingle', 'handGroup1', 'handAll'].indexOf(command.code) != -1">
        <Input v-model="form.outputPower2" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc')+'1'" v-if="['handSingle', 'handGroup1', 'handAll'].indexOf(command.code) != -1">
        <Input v-model="form.outputColor1" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.lighting.oc')+'2'" v-if="['handSingle', 'handGroup1', 'handAll'].indexOf(command.code) != -1">
        <Input v-model="form.outputColor2" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem :label="showLang('com.time.delay')" v-if="['handSingle','handAdjust', 'openSingle', 'closeSingle', 'handGroup1', 'handAll', 'handGroup11', 'handGroup10', 'handAll1', 'handAll0'].indexOf(command.code) != -1">
        <div v-if="form.expire" style="font-size: 40px; margin: 20px">
          {{new Date(form.expire).format('yyyy-MM-dd HH:mm:ss')}}
        </div>
        <div style="display: flex;align-items:center;">
          <!-- <i-switch v-model="form.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
            <span slot="open">开灯</span>
            <span slot="close">关灯</span>
          </i-switch> -->
          <DatePicker type="datetime" v-model="form.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.ins.timeTable')" v-if="['setDayPlan1'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.timeId">
          <template v-for="item in timeTables">
            <Option v-if="item.type == 6" :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.ins.timeTable')" v-if="['setDayPlan2'].indexOf(command.code) != -1" class="form-item">
        <Select :placeholder="showLang('save.select')" v-model="form.timeId">
          <template v-for="item in timeTables">
            <Option v-if="item.type == 7" :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.but.tableType')" v-if="['setPlanType'].indexOf(command.code) != -1" class="form-item">
        <RadioGroup v-model="form.planType">
          <Radio :label="0">{{showLang('com.but.timing')}}</Radio>
          <Radio :label="1">{{showLang('com.but.ontime')}}</Radio>
          <Radio :label="2">{{showLang('com.but.mode.light')}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem :label="showLang('com.ins.light.val')" v-if="['setLuxOpenValue'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.lux" type="number" number style="width: 120px; height: 32px">
        <!-- <span slot="append">字节</span> -->
        </Input>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')" v-if="['setLuxOutput'].indexOf(command.code) != -1" class="form-item">
        1: <Select :placeholder="showLang('save.select')" v-model="form.channels[0]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
        2: <Select :placeholder="showLang('save.select')" v-model="form.channels[1]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
        3: <Select :placeholder="showLang('save.select')" v-model="form.channels[2]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
        4: <Select :placeholder="showLang('save.select')" v-model="form.channels[3]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem :label="showLang('com.ins.ch')" v-if="['setLuxOutput'].indexOf(command.code) != -1" class="form-item">
        5: <Select :placeholder="showLang('save.select')" v-model="form.channels[4]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
        6: <Select :placeholder="showLang('save.select')" v-model="form.channels[5]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
        7: <Select :placeholder="showLang('save.select')" v-model="form.channels[6]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
        8: <Select :placeholder="showLang('save.select')" v-model="form.channels[7]" style="width: 90px;">
          <template v-for="item in mode">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>


      <FormItem :label="showLang('com.tab.ip.port')" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.ipport" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.file.path')" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.file" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.login.account')" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.user" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.but.ftp.pass')" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.pswd1" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.tab.size.file')" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.len" type="number" number style="width: 220px; height: 32px">
          <span slot="append">{{showLang('com.unit.byte')}}</span>
          </Input>
        </div>
      </FormItem>
      <FormItem :label="showLang('com.check.word')" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.crc" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" @click="ok">{{showLang('com.lighting.sending')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalArgEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default() { return {}; }
    }
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      events: {},
      timeTables: [],
      areas: [],
      mode: [
        { name: '0 - 10V', id: 0 },
        { name: '0 - 5V', id: 1 },
        { name: '10 - 0V', id: 2 },
        { name: '5 - 0V', id: 3 },
      ],
      form: {
        netType: true, //网络类型：静态=0xFF,动态=0x00,默认=0xFF
        packLen: 512, //OTA升级包大小
        content: null, //OTA文件内容
        pswd: '', //录入密码
        single: true, //显示单日或整月
        date: '', //日期
        expire: '', //过期时间
        expireConfirm: '',
        open: true, //开关灯
        currentRate: 1000, //设置电流倍率
        timeId: 0, //时间表
        planType: 0, //日表模式
        groupNo: 0, //分组编号
        power: 0, //调光亮度
        outputPower1: 0, //调光亮度
        outputPower2: 0, //调光亮度
        outputColor1: 0, //调光色温
        outputColor2: 0, //调光色温
        host: '', //ip或域名
        port: 59996, //端口
        heart: 30, //心跳时间，单位秒
        ll: 30, //轻微漏电阈值
        lh: 100, //严重漏电阈值
        lx: 2000, //跳闸漏电阈值
        channel: 1, //通道
        speed: 7, //速率，默认7
        weekCtrls: [true, true, true, true, true, true, true, true, true, true, true, true, true, true],
        address: 1,
        comname: 1,
        comtype: 1,
        combuad: 6,
        comdata: 8,
        comparity: 0,
        comstop: 1,
        flag: false,
        lux: 100, //光感值
        ipport: '211.162.119.217:20010',
        file: '', //'bin/App8051v515.bin',
        user: 'amdm',
        pswd1: '0755@123.com',
        len: 109492,
        crc: '9CDD',
        timeTableSpan1: true, //时间表时段1是否启用
        timeTableSpan2: true,
        timeTableSpan3: true,
        timeTableSpan4: true,
        groupId: 0,
        slaveHost: '0.0.0.0',
        slavePort: 49996,
        apnHost: 'internet',
        apnUser: '',
        apnPswd: '',
        channels: [0, 1, 2, 3, 0, 1, 2, 3]
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd', 'showLang']),

    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'weeks1', 'ampm', 'coms8088names', 'com8088types', 'com8088buads', 'com8088datas', 'com8088paritys', 'com8088stops',
      'comNames', 'comTypes', 'comBuads', 'comDatas', 'comParitys', 'comStops']),
    needCheck: function () {
      return this.needCheckPswd && this.command.pswd;
    },
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.form.port = localStorage.getItem('portRecord') == null ? 59996 : localStorage.getItem('portRecord');//默认端口
        this.form.content = null;
        this.form.packLen = 512;
        switch (this.command.code) {
          case 'setEventEnable': {
            this.getEvents();
            break;
          }
          case 'getYearPlan': {
            this.form.single = this.command.args.single;
            this.form.date = this.command.args.date;
            break;
          }
          case 'handControl': {
            this.form.open = this.command.args.open;
            this.form.expire = this.command.args.expire;
            this.form.expireConfirm = this.command.args.expire;
            break;
          }
          case 'setCurrentRate': {
            this.form.currentRate = this.command.args.currentRate;
            break;
          }
          case 'setLeakageRate': {
            this.form.ll = this.command.args.ll;
            this.form.lh = this.command.args.lh;
            this.form.lx = this.command.args.lx;
            break;
          }
          case 'handGroup11':
          case 'handGroup10':
          case 'handGroup1': {
            this.queryAreas();
            let d = new Date();
            d.setHours(d.getHours() + 1);
            this.form.expire = d.format('yyyy-MM-dd HH:mm:ss');
            this.form.channel = 6;
            break;
          }
          case 'handSingle':
          case 'closeSingle':
          case 'handAll1':
          case 'handAll0':
          case 'handAll':
          case 'setallclock':
          case 'handAdjust':
          case 'openSingle': {
            // this.form.open = this.command.args.open;
            let d = new Date();
            d.setHours(d.getHours() + 1);
            this.form.expire = d.format('yyyy-MM-dd HH:mm:ss');
            this.form.channel = 6;
            break;
          }
          case 'setDayPlan1':
          case 'setDayPlan2': {
            this.form.timeId = 0;
            // console.log('set time id', this.form.timeId)
            this.getUseablePlan();
            break;
          }
        }
        // console.log('show command', this.command)
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    'form.port'() {
      localStorage.setItem('portRecord', this.form.port);
    },
  },
  methods: {
    eyeOutline() {
      this.$refs.pswd.type = 'text'
      setTimeout(() => {
        if (this.$refs.pswd) {
          this.$refs.pswd.type = 'password'
        }
      }, 3000)
      console.log('eyeOutline')
    },
    queryAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    getEvents: function () {
      this.$axios.post(`//${this.domains.trans}/station/enum/BJ8051Events`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'events', res.data);
        }
      });
    },
    createCmd: function () {
      let args;
      if (this.command.code == 'setLightUpdate') {
        args = { flag: this.form.flag ? 1 : 0 };
      } else if (this.command.code == 'setSaveOtaFile') {
        args = { flag: this.form.flag ? 1 : 0 };
      } else if (this.command.code == 'openSingle') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { bright1: 100, bright2: 100, color1: 100, color2: 0, expire: expire };
      } else if (this.command.code == 'closeSingle') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { bright1: 0, bright2: 0, color1: 0, color2: 0, expire: expire };
      } else if (this.command.code == 'handSingle') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { bright1: this.form.outputPower1, bright2: this.form.outputPower2, color1: this.form.outputColor1, color2: this.form.outputColor2, expire: expire };
      } else if (this.command.code == 'handGroup1') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        let areas = this.areas.filter(p => p.id == this.form.groupId);
        // console.log('sel area', areas[0]);
        args = {
          channel: this.form.channel,
          no: areas[0].code,
          bright1: this.form.outputPower1,
          bright2: this.form.outputPower2,
          color1: this.form.outputColor1,
          color2: this.form.outputColor2,
          expire: expire
        };
      } else if (this.command.code == 'handGroup11') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        let areas = this.areas.filter(p => p.id == this.form.groupId);
        // console.log('sel area', areas[0]);
        args = { channel: this.form.channel, no: areas[0].code, bright1: 100, bright2: 100, color1: 0, color2: 0, expire: expire };
      } else if (this.command.code == 'handGroup10') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        let areas = this.areas.filter(p => p.id == this.form.groupId);
        // console.log('sel area', areas[0]);
        args = { channel: this.form.channel, no: areas[0].code, bright1: 0, bright2: 0, color1: 0, color2: 0, expire: expire };
      } else if (this.command.code == 'handAll') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { channel: this.form.channel, bright1: this.form.outputPower1, bright2: this.form.outputPower2, color1: this.form.outputColor1, color2: this.form.outputColor2, expire: expire };
      } else if (this.command.code == 'handAll1') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { channel: this.form.channel, bright1: 100, bright2: 100, color1: 0, color2: 0, expire: expire };
      } else if (this.command.code == 'handAll0') {
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = { channel: this.form.channel, bright1: 0, bright2: 0, color1: 0, color2: 0, expire: expire };
      } else if (this.command.code == 'setallclock') {
        args = { channel: this.form.channel };
      } else if (this.command.code == 'setGroup') {
        args = { no: this.form.groupNo };
      } else if (this.command.code == 'setPlanType') {
        args = { type: this.form.planType };
      } else if (this.command.code == 'setDayPlan1' || this.command.code == 'setDayPlan2') {
        args = { timeId: this.form.timeId };
      } else if (this.command.code == 'handControl') {
        let expire = new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss');
        args = { open: this.form.open, enable: true, expire: expire, point: false };
      } else if (this.command.code == 'setTimeEnable') {
        let list = [];
        if (this.form.timeTableSpan1) {
          list.push(1);
        }
        if (this.form.timeTableSpan2) {
          list.push(2);
        }
        if (this.form.timeTableSpan3) {
          list.push(3);
        }
        if (this.form.timeTableSpan4) {
          list.push(4);
        }
        args = { list: list };
      } else if (this.command.code == 'getYearPlan') {
        let date = new Date(this.form.date);
        args = { single: this.form.single, month: date.getMonth() + 1, day: date.getDate() };
      } else if (this.command.code == 'setCurrentRate') {
        args = { k: this.form.currentRate };
      } else if (this.command.code == 'update' || this.command.code == 'startSaveOtaFile') {
        // console.log('args saved', this.form.content)
        args = { version: 3, flag: 0, len: this.form.packLen, content: this.form.content };
      } else if (this.command.code == 'setNetwork') {
        args = {
          host: this.form.host,
          port: this.form.port,
          heart: this.form.heart,
          slaveHost: this.form.slaveHost,
          slavePort: this.form.slavePort,
          apnHost: this.form.apnHost,
          apnUser: this.form.apnUser,
          apnPswd: this.form.apnPswd,
        };
      } else if (this.command.code == 'setK5Network') {
        args = {
          type: this.form.netType ? 1 : 0,
          ip: this.form.host,
        }
      } else if (this.command.code == 'setDefaultNetwork') {
        args = { host: this.form.host, port: this.form.port, heart: this.form.heart };
      } else if (this.command.code == 'setLoraWork') {
        args = { channel: this.form.channel, speed: this.form.speed };
      } else if (this.command.code == 'setLeakageRate') {
        args = { ll: this.form.ll, lh: this.form.lh, lx: this.form.lx };
      } else if (this.command.code == 'setComArgs') {
        args = { com: this.form.comname, type: this.form.comtype, buad: this.form.combuad, data: this.form.comdata, parity: this.form.comparity, stop: this.form.comstop };
      } else if (this.command.code == 'setComArgs1') {
        args = { address: this.form.address, buad: this.form.combuad, parity: this.form.comparity };
      } else if (this.command.code == 'getComArgs') {
        args = { com: this.form.comname };
      } else if (this.command.code == 'ftp') {
        if (!this.form.file || this.form.file.length == 0) {
          this.$Message.warning('请输入文件名称');
          return
        }
        args = { ipport: this.form.ipport, file: this.form.file, user: this.form.user, pswd: this.form.pswd1, len: this.form.len, crc: this.form.crc };
        // }else if(this.command.code == 'setWeekControl'){
        //   let list = [];
        //   for(let i = 0; i < 7; i++){
        //     for(let j = 0; j < 2; j++){
        //       let idx = i * 2 + j;
        //       list.push({week: i+1, am: j == 0, enable: this.form.weekCtrls[idx]});
        //     }
        //   }
        //   args = {list}
      } else if (this.command.code == 'setLuxOpenValue') {
        args = { lux: this.form.lux };
      }else if (this.command.code == 'setLuxOutput') {
        args = { channels: this.form.channels };
      }else if (this.command.code == 'handAdjust') {
        let expire = new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss');
        args = {"channel": this.form.channel*1, "power": this.form.power*1, "expire": expire}
      } else {
        args = {};
      }
      // this.showModal = false;
      this.$emit('saved', { code: this.command.code, paramsCode: this.command.paramsCode, name: this.command.name, args });
      this.form.pswd = '';
    },
    ok: function () {
      // console.log('args saved before 1', this.command.code)
      if (this.command.code == "update" || this.command.code == 'startSaveOtaFile') {
        if (this.$refs.fileInput.files.length == 0) {
          this.$Message.warning(this.showLang('save.upgrade.file'));
          return;
        }
        if (this.form.pswd == '') {
          this.$Message.warning(this.showLang('save.batch.pswd'));
          return;
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code != 0) {
            // this.$Message.error('密码错误');
            return;
          } else {
            var reader = new FileReader(); //实例化文件读取对象
            reader.readAsDataURL(this.$refs.fileInput.files[0]); //将文件读取为 DataURL,也就是base64编码
            let that = this;
            reader.onload = function (ev) {
              //文件读取成功完成时触发
              var dataURL = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
              // document.querySelector("img").src = dataURL; //将DataURL码赋值给img标签
              that.form.content = dataURL.replace("data:application/octet-stream;base64,", "");
              // that.$emit('saved', {code: that.code, name: that.name, packLen: that.form.packLen, args: dataURL})
              // that.argsSaved({
              //   code: that.code,
              //   name: that.name,
              //   args: { len: that.form.packLen, content: dataURL },
              // });
              // console.log('args saved before', that.form.content)
              that.createCmd();
              // that.loading = false;
              // that.showModal = false;
              that.$refs.fileInput.value = "";
            };
          }
        });
        return;
      }
      if (this.command.code == 'openSingle' || this.command.code == 'closeSingle') {
        let d = new Date(this.form.expire);
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'));
          return;
        }
      }
      if (this.command.code == 'setLuxOpenValue') {
        if (this.form.lux < 0 || this.form.lux > 65535) {
          this.$Message.warning(this.showLang('com.ins.light.val.between', 0, 65535));
          return;
        }
      }
      if (this.command.code == 'handGroup1') {
        if (this.form.groupId <= 0) {
          this.$Message.warning(this.showLang('select.group'));
          return;
        }
      }
      if (this.command.code == 'setGroup') {
        if (this.form.groupNo < 0 || this.form.groupNo > 255) {
          this.$Message.warning(this.showLang('com.save.group.err.code', 0, 255));
          return;
        }
      }
      if (this.command.code == 'setDayPlan1' || this.command.code == 'setDayPlan2') {
        // console.log('get time id', this.form.timeId)
        if (!this.form.timeId) this.form.timeId = 0;
        if (this.form.timeId <= 0) {
          this.$Message.warning(this.showLang('save.light.plan.need'));
          return;
        }
      }
      if (this.command.code == 'handControl') {
        let d = new Date(this.form.expire);
        if (d < new Date()) {
          this.$Message.warning(this.showLang('com.time.future.choose'));
          return;
        }
        let expire = new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss');
        let confirm = new Date(this.form.expireConfirm).format('yyyy-MM-dd HH:mm:ss');
        if (expire != confirm) {
          this.$Message.error(this.showLang('com.time.delay.vary'));
          return;
        }
      }
      if (this.command.code == 'setCurrentRate') {
        // if(this.form.currentRate < 1 || this.form.currentRate > 65535){
        //   this.$Message.warning('电流倍率必须在 1 - 65535 之间');
        //   return;
        // }
      }
      if (this.command.code == 'setNetwork') {
        if (this.form.port < 0 || this.form.port > 65535) {
          this.$Message.warning(this.showLang('com.save.group.port.code', 1, 65535));
          return;
        }
      }
      if (this.command.code == 'setLoraWork') {
        if (this.form.channel < 0 || this.form.channel > 100) {
          this.$Message.warning(this.showLang('com.save.group.ch.code'));
          return;
        }
      }
      if (this.command.code == 'setLeakageRate') {
        if (parseInt(this.form.ll) < 0) {
          this.$Message.warning(this.showLang('com.lighting.minor.leakage.pass', 0));
          return;
        }
        if (parseInt(this.form.lh) < parseInt(this.form.ll)) {
          this.$Message.warning(this.showLang('com.lighting.minor.leakage.pass1'));
          return;
        }
        if (parseInt(this.form.lx) < parseInt(this.form.lh)) {
          this.$Message.warning(this.showLang('com.lighting.serious.leakage.pass1'));
          return;
        }
      }
      if (this.needCheck) {
        if (this.form.pswd == '') {
          this.$Message.warning(this.showLang('save.batch.pswd'));
          return;
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code != 0) {
            // this.$Message.error('密码错误');
            return;
          } else {
            this.createCmd();
          }
        });
      } else {
        this.createCmd();
      }
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>